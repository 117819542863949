import React from 'react';

import Main from './Main';

const HomeScreen = () => (
  <>
    <Main />
  </>
);

export default HomeScreen;
