import React from 'react';
import Logo from '../../assets/images/logo.svg';
import Applestore from '../../assets/images/appstore.svg';
import Googleplay from '../../assets/images/googleplay.png';
import './Main.css';

const Main = () => (
  <div className="WrapperMain">
    <div className="MainConteTitla animate__animated animate__fadeIn">
      <p className="MainTitla">Es</p>
      <p className="MainTitla">tiempo</p>
      <p className="MainTitla">
        de hablar<span className="OrangeDot">.</span>
      </p>
    </div>
    <div className="MainConteButton animate__animated animate__fadeIn">
      {/* <p className="MainText">
        Pronto podrás decir eso que tienes que decir en nuestra app..<span className="OrangeDot">.</span>
      </p> */}
      <p className="MainText">
        No importa si eres
        <span className="MainTextBold">
          <span className="OrangeDot"> #</span>TeamAndroid
        </span>
        <span> o </span>
        <span className="MainTextBold">
          <span className="OrangeDot"> #</span>TeamIOS
        </span>
        , descarga la aplicación y dilo cuando quieras<span className="OrangeDot">:</span>
      </p>
      <div className="StoreImgsContainer">
        <a href="https://apps.apple.com/mx/app/spiky/id6443969119" className="AnchStoreImg">
          <img className="StoreImg AppSore" src={Applestore} alt="App Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.spikyapp"
          className="AnchStoreImg"
        >
          <img className="StoreImg" src={Googleplay} alt="Google play" />
        </a>
      </div>
      <div>
        <a href="/privacidad" className="MainLink">
          Conoce nuestro Aviso de Privacidad
        </a>
      </div>
      <div>
        <a
          href="mailto:admin@spiky.com.mx?subject=Tengo%20una%20pregunta&body=Necesito%20ayuda%20"
          className="MainLink"
        >
          ¿Necesitas ayuda? Mándanos un email a admin@spiky.com.mx
        </a>
      </div>
    </div>
    <img className="MenuLogoMain animate__animated animate__fadeIn" src={Logo} alt="logo" />
  </div>
);

export default Main;
