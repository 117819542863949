import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { SocketProvider } from './context/SocketContext';
import { AppRouter } from './routers/AppRouter';
import './styles/normalize.css';

export const App = () => {
  return (
    <Provider store={store}>
      <SocketProvider>
        <AppRouter />
      </SocketProvider>
    </Provider>
  );
};
