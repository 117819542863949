import React from 'react';
import { Link } from 'react-router-dom';
import './ArrowBack.css';
import arrow from '../../assets/images/arrow.svg';

const ArrowBack = ({ path }) => {
  return (
    <div className="ArrowBackConte animate__animated animate__fadeIn">
      <Link to={path}>
        <img className="ImgArrowB" src={arrow} alt="flecha" />
      </Link>
    </div>
  );
};

export default ArrowBack;
