import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MiddApp = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.spikyapp';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/mx/app/spiky/id6443969119';
    } else {
      navigate('/');
    }
  });

  return children;
};

export default MiddApp;
