import React from 'react';
import './PrivacySection.css';

const PrivacySection = ({ elements, section }) => {
  return (
    <div className="">
      <div className="InfoConteTerms animate__animated animate__fadeIn">
        <p className="TermsSubTitle">{section}</p>
        <div className="ConteTerms">
          <p className="TermsText">
            {elements.map(v => (
              <>
                <h3>{v.title}</h3>
                {v.paragraphs.map(p => (
                  <p>{p}</p>
                ))}
              </>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacySection;
