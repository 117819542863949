import React, { useEffect } from 'react';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authNewNotification } from '../actions/auth';
import { uiOpenModalPopUp } from '../actions/ui';
import { useSocket } from '../hooks/useSocket';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { socket, online, conectarSocket, desconectarSocket } = useSocket(
    process.env.REACT_APP_SOCKET_URL
  );
  const { uid } = useSelector(state => state.auth);
  const { modelOpenPopUp } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uid) {
      conectarSocket();
    }
  }, [uid, conectarSocket]);

  useEffect(() => {
    if (!uid) {
      desconectarSocket();
    }
  }, [uid, desconectarSocket]);

  useEffect(() => {
    socket?.on('evento', resp => {});
  }, [socket]);

  useEffect(() => {
    socket?.on('add-conver', resp => {
      console.log(resp);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on('chatmsg', resp => {
      console.log(resp);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on('notify', resp => {
      dispatch(authNewNotification());
      if (!modelOpenPopUp.open) {
        dispatch(uiOpenModalPopUp(resp));
      }
    });
  }, [socket, dispatch]);

  return <SocketContext.Provider value={{ socket, online }}>{children}</SocketContext.Provider>;
};
