import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
//Functions
import { startChecking } from '../actions/auth';
import Checking from '../components/Checking';
import Error404 from '../components/Error404';
import ScrollToTop from '../components/Funciones/ScrollToTop';
import HomeScreen from '../components/HomeScreen';
import RedirectScreen from '../components/RedirectScreen';
import { ModalAlert } from '../components/ModalAlert';
import '../styles/App.css';
//Style
import '../styles/index.css';
import '../styles/normalize.css';
//Components
import { PublicRoute } from './PublicRoute';
import { PrivacyScreen } from '../components/PrivacyScreen';
import MiddApp from '../middlewares/MiddApp';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, uid } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking) {
    return <Checking />;
  }

  return (
    <div className="App">
      <div className="WrapperApp">
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute isAuthenticated={!!uid}>
                <HomeScreen />
              </PublicRoute>
            }
          />
          <Route
            path="/app"
            element={
              <PublicRoute isAuthenticated={!!uid}>
                <MiddApp>
                  <HomeScreen />
                </MiddApp>
              </PublicRoute>
            }
          />
          <Route
            path="/privacidad"
            element={
              <PublicRoute isAuthenticated={!!uid}>
                <PrivacyScreen />
              </PublicRoute>
            }
          />
          <Route
            path="/redirect"
            element={
              <PublicRoute isAuthenticated={!!uid}>
                <RedirectScreen />
              </PublicRoute>
            }
          />

          <Route path="/*" element={<Error404 msg={'Page no found it'} number={404} />} />
        </Routes>
        <ModalAlert />
      </div>
    </div>
  );
};
