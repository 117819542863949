import React, { useEffect, useState } from 'react';
import ArrowBack from './ArrowBack';
import Logo from '../assets/images/logo.svg';
import { fetchSinToken } from '../helpers/fetch';
import PrivacySection from './PrivacySection';

export const PrivacyScreen = () => {
  const [docs, setDocs] = useState({});
  const [ok, setOk] = useState(false);
  const terms = 'Términos y Condiciones';
  const notice = 'Aviso de privacidad';
  const [alternativeOption, setAlternativeOption] = useState(terms);
  const getPrivacyDocuments = async () => {
    try {
      const resp = await fetchSinToken('lists/terms', 'GET');
      const body = await resp.json();
      setDocs(body.lists);
      setOk(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!ok) getPrivacyDocuments();
  }, [ok]);
  console.log(docs);
  const handleChangeView = () => {
    if (alternativeOption === terms) {
      setAlternativeOption(notice);
    } else {
      setAlternativeOption(terms);
    }
  };
  return (
    <div className="WrapperMain">
      <ArrowBack path="/" />
      <div className="MainConteTitla animate__animated animate__fadeIn">
        <p className="MainTitla">
          blablabla..<span className="OrangeDot">.</span>
        </p>
      </div>
      {alternativeOption === terms
        ? 'noticeOfPrivacy' in docs && (
            <div className="MainConteButton animate__animated animate__fadeIn">
              <PrivacySection elements={docs.noticeOfPrivacy} section={notice} />
            </div>
          )
        : 'termsAndConditions' in docs && (
            <div className="MainConteButton animate__animated animate__fadeIn">
              <PrivacySection elements={docs.termsAndConditions} section={terms} />
            </div>
          )}
      <button className={'Button'} onClick={handleChangeView}>
        {alternativeOption}
      </button>
      <img className="MenuLogoMain animate__animated animate__fadeIn" src={Logo} alt="logo" />
    </div>
  );
};
