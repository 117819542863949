import { types } from '../types/types';

const initialState = {
  chatActivo: null,
  mensajes: [],
  convers: [],
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
