import React from 'react';
import './Checking.css';

const Checking = () => {
  return (
    <div className="WrapperChecking">
      <div className="ConteChecking animate__animated animate__fadeIn">
        {/* <img className="CheckingImg" src={Icon} alt="icon-blue" />    */}
        <div className="CheckingImg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="223.442"
            viewBox="0 0 220.944 223.442"
          >
            <g
              id="Grupo_293"
              data-name="Grupo 293"
              transform="translate(-1014.454 95.756) rotate(-22)"
            >
              <path
                id="Trazado_307"
                data-name="Trazado 307"
                d="M67.88,7.695a4,4,0,0,1,7.239,0l65.2,138.6a4,4,0,0,1-3.62,5.7H6.3a4,4,0,0,1-3.62-5.7Z"
                transform="translate(981.068 540) rotate(-114)"
                fill="#01192e"
              />
              <rect
                id="Rectángulo_370"
                data-name="Rectángulo 370"
                width="31.252"
                height="13.331"
                rx="2"
                transform="matrix(0.53, -0.848, 0.848, 0.53, 1100.367, 374.495)"
                fill="#FC702A"
              />
              <g
                id="Elipse_59"
                data-name="Elipse 59"
                transform="translate(988.084 447.584)"
                fill="none"
                stroke="#01192e"
                strokeWidth="16"
              >
                <circle cx="30" cy="30" r="30" stroke="none" />
                <circle cx="30" cy="30" r="22" fill="none" />
              </g>
              <path
                id="Trazado_306"
                data-name="Trazado 306"
                d="M3.732,1l25.52-1a2,2,0,0,1,2,2v9.331a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2C0,.9,2.627,1,3.732,1Z"
                transform="matrix(0.899, -0.438, 0.438, 0.899, 1119.978, 393.059)"
                fill="#FC702A"
              />
              <rect
                id="Rectángulo_372"
                data-name="Rectángulo 372"
                width="31.252"
                height="13.331"
                rx="2"
                transform="translate(1126.326 420.544) rotate(8)"
                fill="#FC702A"
              />
            </g>
          </svg>
        </div>
        <p className="TitleChecking">Cargando</p>
      </div>
    </div>
  );
};

export default Checking;
