import React from 'react';
import Logo from '../../assets/images/name-logo.svg';
import Applestore from '../../assets/images/appstore.svg';
import Googleplay from '../../assets/images/googleplay.png';
import './RedirectScreen.css';

const RedirectScreen = () => {
  const params = new URL(document.location).searchParams;
  const token = params.get('token') || '';
  const correoValid = params.get('correoValid') || '';
  const screen = params.get('screen') || '';

  window.location.href = `spikyapp://${screen}?token=${token}&correoValid=${correoValid}`;

  return (
    <div className="WrapperMain">
      <div className="RedirectConteTitla animate__animated animate__fadeIn">
        <p className="RedirectTitla">Descarga la aplicación</p>
        <p className="RedirectTitla">
          y dilo cuando quieras<span className="OrangeDot">.</span>
        </p>
      </div>
      <div className="RedirectConteButton animate__animated animate__fadeIn">
        <div className="StoreImgsContainer">
          <a href="https://apps.apple.com/mx/app/spiky/id6443969119" className="AnchStoreImg">
            <img className="StoreImg AppSore" src={Applestore} alt="App Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.spikyapp"
            className="AnchStoreImg"
          >
            <img className="StoreImg" src={Googleplay} alt="Google play" />
          </a>
        </div>
      </div>
      <img className="MenuLogoRedirect animate__animated animate__fadeIn" src={Logo} alt="logo" />
    </div>
  );
};

export default RedirectScreen;
