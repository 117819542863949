import { types } from '../types/types';

export const uiOpenModalM = () => ({
  type: types.uiOpenModalM,
});

export const uiCloseModalM = () => ({
  type: types.uiCloseModalM,
});

export const uiOpenModalR = () => ({
  type: types.uiOpenModalR,
});

export const uiCloseModalR = () => ({
  type: types.uiCloseModalR,
});

export const uiOpenAlert = resp => ({
  type: types.uiOpenAlert,
  payload: resp,
});

export const uiCloseAlert = () => ({
  type: types.uiCloseAlert,
});

export const uiSetLoading = state => ({
  type: types.uiSetLoading,
  payload: state,
});

export const uiSetUniversities = data => ({
  type: types.uiSetUniversities,
  payload: data,
});

export const uiOpenModalAlert = data => ({
  type: types.uiOpenModalAlert,
  payload: data,
});

export const uiCloseModalAlert = () => ({
  type: types.uiCloseModalAlert,
});
export const uiOpenModalReport = () => ({
  type: types.uiOpenModalReport,
});

export const uiCloseModalReport = () => ({
  type: types.uiCloseModalReport,
});

export const uiOpenModalReply = () => ({
  type: types.uiOpenModalReply,
});

export const uiCloseModalReply = () => ({
  type: types.uiCloseModalReply,
});

export const uiOpenModalNotify = () => ({
  type: types.uiOpenModalNotify,
});
export const uiCloseModalNotify = () => ({
  type: types.uiCloseModalNotify,
});

export const uiOpenModalNotifications = () => ({
  type: types.uiOpenModalNotifications,
});
export const uiCloseModalNotifications = () => ({
  type: types.uiCloseModalNotifications,
});

export const uiOpenModalPopUp = data => ({
  type: types.uiOpenModalPopUp,
  payload: data,
});
export const uiCloseModalPopUp = () => ({
  type: types.uiCloseModalPopUp,
});
