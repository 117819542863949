import { fetchConToken, fetchConTokenQuery, fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';
import { msgLogout } from './message';
import { uiOpenAlert, uiOpenModalAlert, uiSetLoading, uiSetUniversities } from './ui';
// import { eventLogout } from "./events";

export const startLogin = (correo, contrasena) => {
  return async dispatch => {
    const resp = await fetchSinToken('auth/login', { correo, contrasena }, 'POST');
    const body = await resp.json();

    if (body.ok) {
      localStorage.setItem('token', body.token);
      localStorage.setItem('token-init-date', new Date().getTime());

      const resp_uni = await fetchConToken('univer');
      const body_uni = await resp_uni.json();

      dispatch(uiSetUniversities(body_uni.universidades));
      dispatch(uiOpenAlert({ ok: body.ok }));

      dispatch(
        login({
          uid: body.uid,
          alias: body.alias,
          universidad: body.universidad,
          n_notificaciones: body.n_notificaciones,
        })
      );
    } else {
      dispatch(uiOpenAlert(body));
    }
  };
};

export const startRegister = (correo, contrasena, alias) => {
  return async dispatch => {
    try {
      dispatch(uiSetLoading(true));
      const params = new URL(document.location).searchParams;
      const token = params.get('token') || '';
      const resp = await fetchConTokenQuery(
        'auth/register',
        { validCorreo: correo, contrasena, alias, keyword: 'VC' },
        'POST',
        token
      );
      const body = await resp.json();

      if (body.ok) {
        localStorage.setItem('token', body.token);
        localStorage.setItem('token-init-date', new Date().getTime());

        const resp_uni = await fetchConToken('univer');
        const body_uni = await resp_uni.json();

        dispatch(uiSetUniversities(body_uni.universidades));

        dispatch(
          login({
            uid: body.uid,
            alias: body.alias,
            universidad: body.universidad,
            n_notificaciones: 0,
          })
        );
      }
      dispatch(uiOpenAlert({ ok: body.ok, msg: body.msg }));
      dispatch(uiSetLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};

export const startChecking = () => {
  return async dispatch => {
    const resp = await fetchConToken('auth/renew');
    const body = await resp.json();

    if (body.ok) {
      localStorage.setItem('token', body.token);
      localStorage.setItem('token-init-date', new Date().getTime());

      // dispatch( uiOpenAlert(body));

      dispatch(
        login({
          uid: body.uid,
          alias: body.alias,
          universidad: body.universidad,
          n_notificaciones: body.n_notificaciones,
        })
      );

      const resp_uni = await fetchConToken('univer');
      const body_uni = await resp_uni.json();

      dispatch(uiSetUniversities(body_uni.universidades));
    } else {
      dispatch(checkingFinish());
    }
  };
};

const checkingFinish = () => ({ type: types.authCheckingFinish });

const login = user => ({
  type: types.authLogin,
  payload: user,
});

export const changePassword = (actualContrasena, nuevaContrasena) => {
  return async (dispatch, getState) => {
    try {
      const { uid } = getState().auth;

      dispatch(uiSetLoading(true));

      const resp = await fetchConToken(
        'auth/change-password',
        { actualContrasena, nuevaContrasena, uid },
        'PUT'
      );
      const body = await resp.json();

      dispatch(uiOpenAlert(body));
      dispatch(uiSetLoading(false));
      if (body.ok) {
        dispatch(
          uiOpenModalAlert({
            icon: 'fa-lock',
            title: 'Constraseña cambiada',
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const startVerifyEmail = correo => {
  return async dispatch => {
    try {
      dispatch(uiSetLoading(true));

      const resp = await fetchSinToken('verif', { correo }, 'POST');
      const body = await resp.json();

      localStorage.setItem('emailtoverfiy', correo);
      localStorage.setItem('oktoverfiy', body.ok);
      body.ok && localStorage.setItem('timetoverif', Date.now());

      dispatch(uiOpenAlert({ msg: body.msg, ok: body.ok }));
      dispatch(uiSetLoading(false));
    } catch (error) {
      localStorage.removeItem('emailtoverfiy');
      localStorage.removeItem('timetoverif');
      console.log(error.errors);
    }
  };
};

export const verifyTokenAccount = (token, correo) => {
  return async dispatch => {
    if (!token & !correo) {
      dispatch(uiOpenAlert({ ok: false, msg: 'Link invalido' }));
      return;
    }

    try {
      const resp = await fetchSinToken(`verif?token=${token}&correoValid=${correo}`);
      const body = await resp.json();
      dispatch(uiOpenAlert(body));
    } catch (error) {
      console.log(error);
    }
  };
};

export const forgotPassword = correo => {
  return async dispatch => {
    try {
      dispatch(uiSetLoading(true));
      const resp = await fetchSinToken('auth/forgot-password?correo=' + correo);
      const body = await resp.json();

      dispatch(uiOpenAlert(body));

      dispatch(uiSetLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};

//Borrar esto
export const verfiyChangeForgotPassword = (token, correo) => {
  return async dispatch => {
    if (!token & !correo) {
      dispatch(uiOpenAlert({ ok: false, msg: 'Link invalido' }));
      return;
    }

    try {
      const resp = await fetchSinToken(
        `auth/change-forgot-password?token=${token}&correoValid=${correo}`
      );
      const body = await resp.json();
      dispatch(uiOpenAlert(body));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeForgotPassword = (validCorreo, contrasena, token) => {
  return async dispatch => {
    try {
      dispatch(uiSetLoading(true));

      const resp = await fetchConTokenQuery(
        'auth/change-forgot-password',
        { validCorreo, contrasena, keyword: 'FG' },
        'PUT',
        token
      );
      const body = await resp.json();

      dispatch(uiOpenAlert(body));
      dispatch(uiSetLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};

export const startLogout = () => {
  return dispatch => {
    localStorage.clear();
    dispatch(logout());
    dispatch(msgLogout());
  };
};

export const UpdateNotificaciones = array_id => {
  return (dispatch, getState) => {
    try {
      const { n_notificaciones } = getState().auth;

      fetchConToken('notif', { id_notificaciones: array_id }, 'PUT');
      dispatch(authUpdateNotifications(n_notificaciones - array_id.length));
    } catch (error) {
      console.log(error);
    }
  };
};

export const authNewNotification = () => ({
  type: types.authNewNotification,
});

const authUpdateNotifications = n => ({
  type: types.authUpdateNotifications,
  payload: n,
});

const logout = () => ({ type: types.authLogout });
