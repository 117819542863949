import React from 'react';
import { Link } from 'react-router-dom';
import './Error404.css';

const Error404 = ({ msg = 'Pagina no encontrada', number = 404 }) => {
  return (
    <div className="WrapperError">
      <div className="ConteNumberError">
        <p className="NumberError">{`${number}.`}</p>
      </div>
      <div className="ConteTextError">
        <div>
          <p className="TextError">{msg}</p>
          <Link className="LinkError" to="/">
            Ir al inicio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
