import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { uiCloseModalAlert } from '../../actions/ui';
import './ModalAlert.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.12)',
  },
};

Modal.setAppElement('#root');

export const ModalAlert = () => {
  const { modelOpenAlert } = useSelector(state => state.ui);
  const dispatch = useDispatch();
  const [state, setState] = useState(modelOpenAlert);

  useEffect(() => {
    if (modelOpenAlert.open) {
      setState(modelOpenAlert);
      setTimeout(() => dispatch(uiCloseModalAlert()), 1100);
    }
  }, [modelOpenAlert, dispatch]);

  return (
    <Modal
      isOpen={modelOpenAlert.open}
      // onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={900}
      overlayClassName="modal-fondo-noti"
    >
      <div className="WrapperModalNoti">
        <div className="ConteModalNoti">
          <i style={state.style} className={`fa-solid ${state.icon}`} />
          <p className="TextModalNoti">{state.title}</p>
        </div>
      </div>
    </Modal>
  );
};
