export const types = {
  uiOpenModalM: '[ui] Open modal message',
  uiCloseModalM: '[ui] Close modal message',
  uiOpenModalR: '[ui] Open modal response',
  uiCloseModalR: '[ui] Close modal response',
  uiOpenModalReport: '[ui] Open modal report',
  uiCloseModalReport: '[ui] Close modal report',
  uiOpenModalReply: '[ui] Open modal reply',
  uiCloseModalReply: '[ui] Close modal reply',
  uiOpenAlert: '[ui] Open alert',
  uiCloseAlert: '[ui] Close alert',
  uiSetLoading: '[ui] Loading msg',
  uiSetUniversities: '[ui] Universities filter',
  uiOpenModalNotifications: '[ui] Open modal notifications',
  uiCloseModalNotifications: '[ui] Close modal notifications',
  uiOpenModalNotify: '[ui] Open modal notify',
  uiCloseModalNotify: '[ui] Close modal notify',
  uiOpenModalAlert: '[ui] Open modal alert',
  uiCloseModalAlert: '[ui] Close modal alert',
  uiOpenModalPopUp: '[ui] Open modal pop-up',
  uiCloseModalPopUp: '[ui] Close modal pop-up',

  authCheckingFinish: '[auth] Finish checking login state',
  authStartLogin: '[auth] Start login',
  authLogin: '[auth] Login',
  authStartRegister: '[auth] Register',
  authStartTokenRenew: '[auth] Start token renew',
  authLogout: '[auth] Logout',
  authNewNotification: '[auth] Add new notification',
  authUpdateNotifications: '[auth] Update notifications',

  msgLogout: '[msg] Remove messages',
  msgStartAddNew: '[msg] Start add new',
  msgAddNew: '[msg] Add new',
  msgNewLoaded: '[msg] New messages loaded',
  msgLoaded: '[msg] Messages loaded',
  msgUpdate: '[msg] Update message reacc/track',
  msgUpdateActiveMsg: '[msg] Update active message reacc/track',
  msgFilter: '[msg] Change filter',
  msgActiveMsg: '[msg] Active message',
  msgDisableMsg: '[msg] Disable message',
  msgDeleteMsg: '[msg] Delete a message',
  msgMoreMsg: '[msg] State button more msg',
  msgSetLoading: '[msg] Change state for loading messages',
  chatGetChats: '[chat] Get chats',
  chatGetMessages: '[chat] Get messages from active chat',
};
